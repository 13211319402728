<template>
  <div>
    <i class="iconfont" :class="name" :style="Object.assign(cstyle,{
      fontSize:size + 'px',
      color: color
    })"></i>
  </div>
</template>

<script>
export default {
  props: {
    cstyle: {
      type: Object,
      default: () => {
        return {}
      },
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 14,
    },
    color: {
      type: String,
      default: '#999',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
