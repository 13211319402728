<template>
  <div class="content-box">
    <div class="logo-box">
      <img :src="fastLogo" alt="">
    </div>
    <h2 style="text-align: center;margin-top:10px;">
      克拉玛依市消防救援支队
    </h2>
    <p style="margin-top: 10px;">--专家查--</p>
    <div style="margin-top: 20px;border-radius: 10px;overflow: hidden;width: 95%">
      <md-field>
        <md-input-item
          v-model="formData.username"
          placeholder="请输入账号"
          :error="error1"
        >
          <template v-slot:left>
            <custom-icon :name="'icon-yonghuming'" :size="18" />
          </template>
        </md-input-item>
        <md-input-item
          type="password"
          v-model="formData.password"
          model-value=""
          placeholder="请输入密码"
          :error="error2"
        >
          <template v-slot:left>
            <custom-icon :name="'icon-mima'" :size="18" />
          </template>
        </md-input-item>
      </md-field>
    </div>
    <div class="btn" @click="goHome">
      <img style="width: 20px;" :src="into" alt="">
      <div style="margin-left: 10px;">登录</div>
    </div>
  </div>
</template>

<script>
import fastLogo from '@/assets/images/logo.png'
import into from '@/assets/images/into.png'
import customIcon from '@/components/custom-icon/index.vue'
import { login } from '@/api/user'
import { Toast } from 'mand-mobile'

export default {
  components: {
    customIcon,
  },
  data() {
    return {
      fastLogo,
      into,
      formData: {},
      error1: undefined,
      error2: undefined,
    }
  },
  methods: {
    goHome() {
      this.error1 = this.formData.username ? undefined : '账号不能为空'
      this.error2 = this.formData.password ? undefined : '密码不能为空'
      if (this.error1 || this.error2) {
        return false
      }
      login({
        password: this.formData.password,
        user_name: this.formData.username,
        type: 'password',
      }).then(res => {
        Toast.info("登录成功")
        window.localStorage.setItem("token", res.data.access_token)
        setTimeout(()=>{
          this.$router.replace({
            path: '/home',
          })
        },1500)
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  color: #333;
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
  background-color: #890000;
  color: #fff;

  .logo-box {
    margin-top: 20px;
    text-align: center;

    img {
      width: 200px;
    }
  }

  .btn {
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(90deg, #00a0e0, #0772af);
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
